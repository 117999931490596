<template>
  <div class="newSeeds">
    <!-- title -->
    <div class="titles">加工记录</div>
    <div class="title">
      <div class="title-top">
        <el-button
          type="primary"
          size="small"
          icon="el-icon-circle-plus-outline"
          @click="addMessage"
          >新增加工</el-button
        >
        <div class="el-title-item">
          <el-form ref="form" :model="logisticsForm" label-width="80px">
            <el-form-item label="加工名称">
              <el-input
                v-model="logisticsForm.name"
                placeholder="请输入关键词"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="title-bottom">
        <div class="title-bottom-left">
          <el-button @click="refresh" icon="el-icon-refresh-right" size="small"
            >刷新</el-button
          >
        </div>
        <div class="title-bottom-right">
          <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="search"
            >搜索</el-button
          >
          <el-button icon="el-icon-refresh" size="small" @click="removeForm"
            >重置</el-button
          >
        </div>
      </div>
    </div>

    <el-table
      v-loading="loading"
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%"
    >
      <el-table-column prop="id" label="序号" align="center"> </el-table-column>

      <el-table-column
        prop="processingName"
        label="加工名称"
        show-overflow-tooltip
        align="center"
      >
      </el-table-column>

      <el-table-column
        prop="plantBatchnum"
        label="采购批次号"
        show-overflow-tooltip
        align="center"
        :formatter="getFarmName"
      >
      </el-table-column>

      <el-table-column
        prop="processingCount"
        label="加工数量"
        show-overflow-tooltip
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="processingCountUnit"
        label="加工单位"
        show-overflow-tooltip
        align="center"
      >
      </el-table-column>

      <el-table-column
        prop="finisheName"
        label="成品名称"
        show-overflow-tooltip
        align="center"
      >
      </el-table-column>

      <el-table-column
        prop="finisheCount"
        label="成品数量"
        show-overflow-tooltip
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="finisheCountUnit"
        label="成品单位"
        show-overflow-tooltip
        align="center"
      >
      </el-table-column>

      <el-table-column
        prop="notekeeper"
        label="记录人"
        show-overflow-tooltip
        align="center"
        :formatter="getTakerName"
      ></el-table-column>

      <el-table-column
        prop="writeTime"
        label="记录时间"
        show-overflow-tooltip
        align="center"
      ></el-table-column>

      <el-table-column
        prop="batchProcessingNum"
        label="加工批次号"
        show-overflow-tooltip
        align="center"
      >
      </el-table-column>

      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <div class="operation">
            <!-- <el-button
              @click="updateClick(scope.row)"
              type="primary"
              size="small"
              icon="el-icon-edit"
            ></el-button> -->
            <el-button
              @click="delList(scope.row)"
              type="primary"
              size="small"
              icon="el-icon-delete"
            ></el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <div class="block pagingBox pagesizeBox">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 50, 100, 150, 200]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="listTotal"
      >
      </el-pagination>
    </div>
    <!-- 新增 -->
    <el-dialog
      title=""
      :visible.sync="updateForm"
      width="31vw"
      class="addAlameBox"
    >
      <div slot="title" class="header-title" style="position: relative">
        <span class="title-name"></span>
        <span class="title-age">{{ boxPrompt }}</span>
        <span
          style="
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0px;
            font-size: 2.22vh;
          "
        >
        </span>
      </div>
      <div class="addAlaForm">
        <el-form ref="upTableData" :model="upTableData" label-width="10vw">
          <el-form-item label="加工名称" prop="processingName">
            <el-input v-model.trim="upTableData.processingName"></el-input>
          </el-form-item>

          <el-form-item label="采购批次号" prop="inventoryId">
            <el-select
              @change="changeRecovery"
              v-model="upTableData.inventoryId"
              placeholder="请选择采收批次"
              style="width: 22vw"
            >
              <el-option
                :label="item.batchNum"
                :value="item.id"
                v-for="item in batchNameList"
                :key="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="加工数量" prop="processingCount">
            <el-input type="number" v-model.trim="upTableData.processingCount"></el-input>
          </el-form-item>
          <el-form-item label="加工数量单位" prop="processingCountUnit">
            <el-input v-model.trim="upTableData.processingCountUnit"></el-input>
          </el-form-item>

          <el-form-item label="成品名称" prop="finisheName">
            <el-input v-model.trim="upTableData.finisheName"></el-input>
          </el-form-item>

          <el-form-item label="成品数量" prop="finisheCount">
            <el-input type="number" v-model.trim="upTableData.finisheCount"></el-input>
          </el-form-item>
          <el-form-item label="成品数量单位" prop="finisheCountUnit">
            <el-input v-model.trim="upTableData.finisheCountUnit"></el-input>
          </el-form-item>
          <el-form-item label="记录人" prop="notekeeper">
            <el-select
              v-model="upTableData.notekeeper"
              placeholder="请输入记录人"
              style="width: 22vw"
            >
              <el-option
                :label="item.name"
                :value="item.id"
                v-for="item in recordNameList"
                :key="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="记录时间" prop="writeTime">
            <el-date-picker
              v-model="upTableData.writeTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="选择日期时间"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item class="isUpload" label="图片" prop="img">
            <el-upload
              class="avatar-uploader"
              action="/imgUpdata"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
              :show-file-list="false"
            >
              <img
                v-if="upTableData.img"
                :src="upTableData.img"
                class="businessImg avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon uploadImg"></i>
            </el-upload>
          </el-form-item>
          <el-form-item size="large">
            <el-button
              class="saveBtn"
              type="primary"
              @click="saveForm('upTableData')"
              >保存</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      upTableData: {
        processingName: "",
        plantBatchnum: "",
        processingCount: "",
        processingCountUnit: "",
        finisheName: "",
        finisheCount: "",
        finisheCountUnit: "",
        notekeeper: "",
        writeTime: "",
        batchProcessingNum: "",
        img: "",
      },
      tableData: [],
      batchNameList: [],
      recordNameList: [],
      boxPrompt: "",
      updateForm: false,
      searchStatus: false,
      currentPage: 1,
      multipleSelection: [],
      logisticsForm: {
        name: "",
        region: -1,
      },
      page: 1,
      size: 10,
      listTotal: 0,
      loading: false,
      counts: 0,
    };
  },
  mounted() {
    this.requrstList();
    this.collectionList();
    this.getNotetakerList();
  },
  methods: {
    //请求列表数据
    requrstList() {
      let that = this;
      this.$get("/specificationmanagement/getSpecificationManagements", {
        page: this.page,
        limit: this.size,
        name: this.logisticsForm.name,
      }).then((res) => {
        if (res.data.state == "success") {
          let m = res.data.datas;
          if (this.page != 1 && m.length == 0) {
            this.page = 1;
            this.requrstList();
          }
          that.tableData = res.data.datas;
          this.listTotal = res.data.count;
        }
        setTimeout(() => {
          this.loading = false;
        }, 500);
      });
    },
    // 获取全部批次列表
    collectionList() {
      this.$get("/inventoryrecords/getPullInventoryrecords", {}).then((res) => {
        if (res.data.state == "success") {
          let data = res.data.datas;
          this.batchNameList = data;
        }
      });
    },

    // 获取全部记录人列表
    getNotetakerList() {
      this.$get("/enterpriseManage/querryStaff", {
        page: 1,
        size: 10000,
      }).then((res) => {
        if (res.data.state == "success") {
          let data = res.data.datas;
          this.recordNameList = data;
        }
      });
    },

    getTakerName(row) {
      let notekeeper = row.notekeeper;
      let recordNameList = this.recordNameList;
      for (var i = 0; i < recordNameList.length; i++) {
        if (recordNameList[i].id == notekeeper) {
          return recordNameList[i].name;
        }
      }
    },

    changeRecovery(val) {
      //console.log(val);
      var batchNameList = this.batchNameList;
      for (var x = 0; x < batchNameList.length; x++) {
        if (batchNameList[x].id == val) {
          this.upTableData.batchProcessingNum = batchNameList[x].batchNum;
          this.upTableData.plantBatchId = batchNameList[x].batchID;
          this.counts = batchNameList[x].count;
        }
      }
    },

    getFarmName(row) {
      let plantBatchId = row.plantBatchId;
      let batchNameList = this.batchNameList;
      for (var m = 0; m < batchNameList.length; m++) {
        if (batchNameList[m].batchID == plantBatchId) {
          return batchNameList[m].batchNum;
        }
      }
    },
    // 编辑
    // updateClick(param2) {
    //   //console.log(param2);
    //   this.boxPrompt = "编辑加工记录";
    //   this.updateForm = true;
    //   this.$nextTick(() => {
    //     this.upTableData = JSON.parse(JSON.stringify(param2));

    //     this.upTableData.plantBatchNum = Number(this.upTableData.plantBatchNum);
    //   });
    // },
    // 新增
    addMessage() {
      this.boxPrompt = "新增加工记录";
      this.updateForm = true;
      this.upTableData = {};
    },
    //删除
    delList(item) {
      //console.log(item);
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$get(
            "/specificationmanagement/deleteSpecificationManagementById",
            {
              id: item.id,
            }
          ).then((res) => {
            //console.log(res);
            if (res.data.state == "success") {
              this.$message.success("删除成功");
              this.requrstList();
            } else {
              this.$message.info("删除失败");
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //保存
    saveForm() {
      let that = this;
      this.$refs["upTableData"].validate((valid) => {
        if (valid) {
          let upTableData = this.upTableData;
          let url = "/specificationmanagement/addSpecificationManagement";
          if (!this.upTableData.processingName) {
            return this.$message.error("加工名称不能为空！");
          } else if (!this.upTableData.inventoryId) {
            return this.$message.error("采收批次不能为空！");
          } else if (!this.upTableData.processingCount) {
            return this.$message.error("加工数量不能为空！");
          } else if (!this.upTableData.finisheName) {
            return this.$message.error("成品名称不能为空");
          } else if (!this.upTableData.finisheCount) {
            return this.$message.error("成品数量不能为空！");
          } else if (!this.upTableData.notekeeper) {
            return this.$message.error("记录人不能为空！");
          } else if (!this.upTableData.writeTime) {
            return this.$message.error("记录时间不能为空！");
          } else if (!this.upTableData.img) {
            return this.$message.error("图片不能为空！");
          } else if (!this.upTableData.processingCountUnit) {
            return this.$message.error("加工数量单位不能为空！");
          } else if (!this.upTableData.finisheCountUnit) {
            return this.$message.error("成品数量单位不能为空！");
          }

          this.$post(url, upTableData).then((res) => {
            this.updateForm = false;
            if (res.data.state == "success") {
              if (this.upTableData.processingCount > this.counts) {
                return this.$message.error(
                  "加工数量不能大于" + this.counts + "!"
                );
              }

              if (this.boxPrompt == "新增加工记录") {
                this.$message.success("添加成功");
              } else {
                this.$message.success("编辑成功");
              }
              this.requrstList();
            } else {
              this.$message.error("加工数量不能大于入库数量！");
              this.updateForm = true;
            }
          });
        } else {
          //console.log("error submit!!");
          return false;
        }
      });
    },

    handleAvatarSuccess(file) {
      this.upTableData.img =
        "https://public.half-half.cn/" + file.data.fileName;
      this.upTableData = JSON.parse(JSON.stringify(this.upTableData));
    },

    beforeAvatarUpload() {},

    //刷新
    refresh() {
      this.loading = true;
      if (!this.searchStatus) {
        this.requrstList();
      } else {
        this.search();
      }
    },
    //搜索
    search() {
      let name = this.logisticsForm.name;
      this.page = 1;
      //   let level = this.logisticsForm.region;
      //   if (level == -1) level = '';
      this.loading = true;
      this.searchStatus = true;
      if (name == "" || !name) {
        this.searchStatus = false;
      }
      this.requrstList(name);
    },
    //重置
    removeForm() {
      this.loading = true;
      this.logisticsForm.name = "";
      this.logisticsForm.region = -1;
      this.requrstList();
    },

    handleSizeChange(val) {
      //console.log(val);
    },
    handleCurrentChange(val) {
      this.page = val;
      this.requrstList();
    },
  },
};
</script>

<style scoped lang="less">
@import "@/style/newBackTable.less";
.operation {
  display: flex;
  justify-content: center;
}
.titles {
  border-bottom: 1px #999 solid;
  padding-bottom: 10px;
  font-size: 1.5vh;
  font-weight: bold;
}
.title {
  margin-top: 1vh;
}
.pagesizeBox {
  margin-top: 0;
}
.isUpload .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.isUpload .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.isUpload .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 147px;
  height: 147px;
  line-height: 147px;
  text-align: center;
  border: 1px solid #8c939d;
  border-radius: 3%;
}
.isUpload .businessImg {
  width: 15vh;
  height: 15vh;
}
</style>
